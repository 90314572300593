<template>
  <div class="container">
    <div class="fontgen">
      <h1>YEARBOOK HEADER GENERATOR</h1>
      <p>Select which font style you want, and the font size. A preview will be generated. Then click download to download as a PNG so you can import into Friesens.</p>
      <div class="preview">
        <p class="label">PREVIEW</p>
        <p :style="preview()" v-if="!allcaps">{{ text }}</p><p :style="preview()" v-else>{{ text.toUpperCase() }}</p>
      </div>
      <div ref="result">
        <p v-if="lastresult != ''">{{ lastresult.message }}</p>
      </div>
      <div class="form-group">
        <div class="form-class">
          <label for="font-style">FONT STYLE</label>
          <select name="font-style" id="font-style" v-model="fontStyle">
            <option value="Bold">Bold</option>
            <option value="Black">Black</option>
            <option value="Regular">Regular</option>
            <option value="Light">Light</option>
          </select>
          <label for="is-allcaps">ALL CAPS</label>
          <input type="checkbox" name="is-allcaps" id="is-allcaps" v-model="allcaps" class="checkbox">
          <label for="font-size">FONT SIZE</label>
          <input type="number" name="font-size" id="font-size" min="1" max="100" step="1" v-model="fontSize">
          <label for="text">TEXT</label>
          <input type="text" name="text" id="text" v-model="text">
          <p class="label">COLOR</p>
          <p>Click to automatically apply a specific color to your text</p>
          <div class="colors-container">
            <div class="colors">
              <div class="color" v-for="color in colorPallette" :key="color.name" @click="this.color.c = color.c; this.color.m = color.m; this.color.y = color.y; this.color.k = color.k">
                <div class="color-display" :style="`background-color: ${CMYKtoHex(color.c, color.m, color.y, color.k)}; width: 50px; height: 50px;`"></div>
                  <div class="color-text">
                    <h3 class="color-name">{{ color.name }}</h3>
                    <p class="color-fr-value">(FR{{ color.fr }})</p>
                    <div class="color-values">
                      <p class="color-value">C: {{ color.c }}</p>
                      <p class="color-value">M: {{ color.m }}</p>
                      <p class="color-value">Y: {{ color.y }}</p>
                      <p class="color-value">K: {{ color.k }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <label for="color-c" style="font-size: 25px;">CYAN</label>
          <input type="range" name="color-c" min="0" max="100" v-model="color.c">
          <p class="subtext">{{ color.c }}%</p>
          <label for="color-m" style="font-size: 25px;">MAGENTA</label>
          <input type="range" name="color-m" min="0" max="100" v-model="color.m">
          <p class="subtext">{{ color.m }}%</p>
          <label for="color-y" style="font-size: 25px;">YELLOW</label>
          <input type="range" name="color-y" min="0" max="100" v-model="color.y">
          <p class="subtext">{{ color.y }}%</p>
          <label for="color-k" style="font-size: 25px;">BLACK (K)</label>
          <input type="range" name="color-k" min="0" max="100" v-model="color.k">
          <p class="subtext">{{ color.k }}%</p>
          <input type="submit" value="SUBMIT" class="submit" @click="download()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      fontStyle: 'Bold',
      fontSize: 40,
      text: "TITLE OF PAGE",
      color: {
        c: 0,
        m: 0,
        y: 0,
        k: 100
      },
      lastresult: "",
      allcaps: true,
      colorPallette: []
    }
  },
  mounted() {
    fetch("https://api.domyjobfor.me/yearbook/colors")
    .then(response => response.json())
    .then(data => {
      this.colorPallette = data.colors;
    })
  },
  methods: {
    download() {
      fetch("https://api.domyjobfor.me/yearbook/generateheader", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          fontStyle: this.fontStyle,
          fontSize: this.fontSize,
          text: this.text,
          color: this.CMYKtoHex(this.color.c, this.color.m, this.color.y, this.color.k),
          capitalize: this.allcaps
        })
      })
      .then(response => response.json())
      .then(data => {
        let file = data.png
        this.lastresult = data;
        // get the file that the page returns and download it
        let link = document.createElement('a');
        if (file != undefined) {
          link.href = `https://api.domyjobfor.me/yearbook/getheader/${file}`;
          link.target = "_blank";
          link.click();
        } else {
          this.lastresult = data;
          this.$refs.result.style.backgroundColor = "red";
        }
        // Scroll to element with ref result to show the result
        this.$refs.result.scrollIntoView({ behavior: 'smooth' });
        this.$refs.result.style.backgroundColor = "none";
      })
    },
    preview() {
        if (this.fontStyle == "Regular") {
          return `font-family: "TorontoSubway"; font-size: ${this.fontSize}pt; color: ${this.CMYKtoHex(this.color.c, this.color.m, this.color.y, this.color.k)};`
        }
        if (this.fontStyle == "Bold") {
          return `font-family: "TorontoSubway"; font-size: ${this.fontSize}pt; font-weight: bold; color: ${this.CMYKtoHex(this.color.c, this.color.m, this.color.y, this.color.k)};`
        }
        return `font-family: "TorontoSubway${this.fontStyle}"; font-size: ${this.fontSize}pt; color: ${this.CMYKtoHex(this.color.c, this.color.m, this.color.y, this.color.k)};`
    },
    CMYKtoHex(c, m, y, k) {
        c = c / 100;
        m = m / 100;
        y = y / 100;
        k = k / 100;

        let r = 1 - Math.min(1, c * (1 - k) + k);
        let g = 1 - Math.min(1, m * (1 - k) + k);
        let b = 1 - Math.min(1, y * (1 - k) + k);

        r = Math.round(r * 255).toString(16);
        g = Math.round(g * 255).toString(16);
        b = Math.round(b * 255).toString(16);

        if (r.length == 1) r = "0" + r;
        if (g.length == 1) g = "0" + g;
        if (b.length == 1) b = "0" + b;

        return `#${r}${g}${b}`;
    }
  }
}
</script>

<style>
#app {
  font-family: "TorontoSubway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}

.form-class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

h1 {
  font-weight: bold;
  font-size: 60px;
}

label, .label {
  font-weighT: bold;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

select {
  font-family: "TorontoSubway";
  font-size: 20px;
  padding: 10px;
  background-color: whitesmoke;
  border: none;
  border-radius: 5px;
  width: 200px;
}
select:hover {
  cursor: pointer;
}

input {
  font-family: "TorontoSubway";
  font-size: 20px;
  padding: 10px;
  background-color: whitesmoke;
  border: none;
  border-radius: 5px;
  max-width: 200px;
  margin-bottom: 0px;
}

.checkbox {
  width: 25px;
  height: 25px;
}

.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.subtext {
  font-size: 20px;
  margin: 0;
}

.submit {
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 50px;
}

.colors-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
}
.colors {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
}
.color {
  width: 200px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0;
  cursor: pointer;
}

.color-text {
  margin: 0;
}


@media (max-width: 1020px) {
  .container {
    width: 90%;
  }
  h1 {
    font-size: 40px;
  }
}

</style>
